import { $post } from '../../api/http.js'
import commonModel from '../../api/common.js'
import tool from '@/util/tool.js'
import moment from 'moment'
class SaasUser{

    /**
     * 获取代理商用户
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} cb 
     */
    getAgentUser(page, limit,extra,cb) {
        let param = { page,per_page:limit}
        if( extra && extra.name ) param.name = extra.name
        commonModel.getDataList("getAgentUserList",param,true,1).then(res=>cb(res))
    }

    /**
     * 删除用户
     * @param {number} id 
     * @param {number} status 1禁用 0启用
     * @param {function} cb 
     */
    deleteUser(id,status,cb){
        let title = `确认${status == 1 ? '禁用' : '启用'}该用户吗?`
        tool.confirm(title).then(()=>{
            $post('delChildUser', { id, status:status == 1 ? 0 : 1 },true, 1).then(res => {
                tool.message("操作成功");
                cb(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 新增或编辑代理商用户
     * @param {object} param 
     * @param {function} cb 
     */
    addOrEditAgentUser(param,cb) {
        if( param.scene && param.scene == 'password'){ //修改密码
            if( !tool.returnMessage(param.password,'请输入新密码')) return
            if( !tool.returnMessage(param.re_password,'请再次输入新密码')) return
			if( param.password !=param.re_password ){
                tool.message("两次密码输入不一致","warning")
                return
            }

            $post('addOrEditAgentUser', param,true ,1).then(res => {
                tool.message('保存成功')
                cb(res.data)
            })
            return
        }

        //新增或编辑
        if( param.validity_time ){
            param.validity_time = moment(param.validity_time).format('YYYY-MM-DD')
        }
        if( !param.user_id ) delete param.user_id
        if( param.user_id ){
            param.type = 1
            param.rank = 2
            param.scene = 'edit'
            delete param.password
        }
        $post('addOrEditAgentUser', param,true ,1).then(res => {
            tool.message('保存成功')
            cb(res.data)
        })
    }

    /**
     * 获取代理商用户
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} cb 
     */
    getAgentByUser(page,limit,extra,cb){
        let param = { page,limit }
        if( extra ){
            if( extra.sort ) param.sort = extra.sort //排序 1创建时间正序 2创建时间倒叙 3登录时间正序 4登录时间倒叙
            if( extra.rank ) param.rank = extra.rank
            if( extra.company_id ) param.company_id = extra.company_id
        }
        commonModel.getDataList("getListByRank",param,true,1).then(res=>cb(res))
    }
}

const saasUserModel = new SaasUser()
export default saasUserModel